<template>
  <div class="align-items-center">
    <span v-if="value.is_double" class="regular-12 badge badge-outline me-2">
      <BaseIcon
        name="shield-order"
        :title="$t(`fraudRisk.doubleOrder`)"
        color="var(--bs-red)"
      />
      {{ $t("fraudRisk.doubleOrder") }}
    </span>
    <BaseIcon
      name="shield-search"
      :title="$t(`fraudRisk.notCalculated`)"
      v-if="value.max === null"
    />
    <BaseIcon
      name="shield-tick"
      :title="value.max + '%'"
      color="var(--bs-green)"
      v-else-if="value.max < 5"
    />
    <BaseIcon
      name="shield-security"
      :title="value.max + '%'"
      color="var(--bs-blue)"
      v-else-if="value.max < 16"
    />
    <BaseIcon
      name="shield-security"
      :title="value.max + '%'"
      color="var(--bs-yellow)"
      v-else-if="value.max < 31"
    />
    <BaseIcon
      name="shield-cross"
      :title="value.max + '%'"
      color="var(--bs-red)"
      v-else
    />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "FraudRisk",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
